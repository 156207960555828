.registerBody{
    padding-top: 100px;
    text-align: center;
    padding-bottom: 41.8vh;
    margin-top: 50px;
    background: url(../img/landingPage/background.jpg) no-repeat ;
    background-size: cover;
    background-position: top center;
    background-clip: padding-box;
    padding: 200px 0;
    width: 100%;
}

.registerBodyContent{
    margin: auto;
    padding: 30px 0px 20px 0px; 
    border-radius: 15px;
    color: white;
    background-color: black;
    width: 52.23%;
}

.registerBodyContent h2{
    font-size: 50px;
}

.registerOptionButton{
    margin: 2px 10px 15px 10px;
    font-size: 24px;
    width: 40%;
}

.registerForm{
    display: none;
}

.registerFormSpacing{
    margin-top: 24px;
}

.registerStudentContent{
    display: none;
}

.registerOrgContent{
    display: none;
}

@media only screen and (max-width: 1200px){
    .registerBodyContent{
        width: 700px;
    }

    .registerBodyContent h2{
        font-size: 45px;
    }

    .registerBodyContent input{
        font-size: 16px;
    }
}

@media only screen and (max-width: 950px){
    .registerBodyContent h2{
        font-size: 35px;
    }
}

@media only screen and (max-width: 700px){
    .registerBodyContent{
        width: 99%;
    }

    .registerBodyContent h2{
        font-size: 20px;
    }

    .registerBodyContent input{
        font-size: 12px;
    }
}