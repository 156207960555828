.main {
  position: relative;
  min-height: 0;
}

.headerText {
  margin-top: 15vh;
  color: white;
  font-style: normal;
  font-size: 2em;
}

.subheaderText {
  margin-top: -1vh;
  color: white;
  font-size: 1.25em;
  font-style: normal;
}

.inputText {
  margin: 16px 0px;
  width: 60%;
  padding: 10px 30px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
  font-size: 1em;
  text-align: center;
}

::placeholder {
  color: #222;
}

.signupBtn {
  margin-top: 14px;
  font-size: 1em;
  font-weight: bold;
  color: white;
  background-color: var(--main-colour);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px 12px 10px 12px;
}

.headerText2 {
  padding-bottom: 3vh;
}

.main2 {
  display: flex;
  justify-content: center;
}

h2 {
  font-size: 2em;
  font-weight: normal;
}

.homeDescription2 span {
  vertical-align: -15%;
  font-size: 2em;
  padding-right: 2vw;
}

.homeBody {
  margin: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  font-size: 34px;
}

.borderBackground {
  border-radius: 15px;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.sectionPosition {
  margin: auto;
  width: 80%;
  padding-top: 5vh;
  padding-bottom: 5vh;
  text-align: center;
}

.homeBody hr {
  background-color: var(--main-colour);
  width: 80%;
  height: 1px;
  border: none;
}

.searchBar {
  padding-top: 5%;
  padding-bottom: 5%;
  margin-top: 10%;
  margin: auto;
  width: 65.2%;
  float: inherit;
  align-items: center;
  justify-content: center;
}

.searchInput {
  margin-bottom: 10px;
  width: 75%;
  border: 10px solid #ffffff;
  border-radius: 10px;
  text-align: center;
  font-size: 14pt;
}

.backgroundheader {
  position: relative;
  z-index: 1;
  background-position-y: center;
  background-image: url(../img/landingPage/background.jpg);
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-size: cover;
  background-attachment: scroll;
  background-origin: padding-box;
  background-clip: border-box;
  width: 100%;
  height: 100vh;
}

.homeDescription {
  width: 30%;
  padding: 0px 10px;
}

.homeDescription2 {
  width: 50%;
  margin: auto;
  padding: 0px 10px;
}

.homeDescription3 {
  width: 50%;
  margin: auto;
  padding: 0px 10px;
}

.homeDescription h3 {
  color: var(--main-colour);
  margin: 2px 0px;
}

.homeDescription2 h3 {
  color: var(--main-colour);
  margin: 2px 0px;
  text-align: left;
  padding-bottom: 3vh;
  text-align: center;
}

.homeDescription3 h3 {
  color: var(--main-colour);
  margin: 2px 0px;
  text-align: left;
  padding-bottom: 3vh;
}
.homeButton {
  cursor: pointer;
  margin: 30px 0;
  padding: 5px 100px;
  font-size: 34px;
  text-decoration: none;
  background-color: var(--main-colour);
  color: #ffffff;
  border: 2px solid var(--main-colour);
  border-radius: 10px;
}

.learnMoreOpen {
  display: block;
  margin: auto;
}

.learnMoreSection {
  display: none;
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

@media screen and (max-width: 650px) {
  .backgroundheader {
    height: 100%;
    padding-top: 0px;
  }
}

@media only screen and (max-width: 1750px) {
  .homeBody {
    font-size: 28px;
  }
}

@media only screen and (max-width: 1450px) {
  .homeBody {
    font-size: 24px;
  }
}

@media only screen and (max-width: 1250px) {
  .homeBody {
    font-size: 20px;
  }

  .homeButton {
    font-size: 28px;
  }
}

@media only screen and (max-width: 1050px) {
  .homeBody {
    font-size: 16px;
  }

  .sectionPostion {
    padding-top: 1vh;
    padding-bottom: 1vh;
  }

  .backgroundheader {
    padding-top: 2vh;
    padding-bottom: 2vh;
  }
}

@media only screen and (max-width: 850px) {
  .homeBody {
    font-size: 15px;
  }

  .sectionPosition {
    width: 97%;
  }

  .searchBar {
    margin-top: 4%;
    margin-bottom: 4%;
    padding-top: 1%;
    float: inherit;
  }

  #homeSearchBar input {
    font-size: 14px;
  }

  .spacing {
    margin: auto;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 5px 100px 5px 100px;
    font-size: 34px;
    text-decoration: none;
    background-color: #6cadf1;
    color: #ffffff;
    border: 2px solid #6cadf1;
    border-radius: 10px;
  }

  .homeButton {
    font-size: 18px;
  }
}

@media only screen and (max-width: 650px) {
  .homeBody {
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 14px;
  }

  .sectionPosition {
    padding-top: 1vh;
    padding-bottom: 1vh;
    padding-left: 0%;
    width: 97%;
  }

  .searchBar {
    float: inherit;
    width: 100%;
  }

  .headertext {
    font-size: 16px;
  }

  #homeSearchBar input {
    font-size: 11px;
  }

  .spacing {
    margin: auto;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 5px 100px 5px 100px;
    font-size: 34px;
    text-decoration: none;
    background-color: #6cadf1;
    color: #ffffff;
    border: 2px solid #6cadf1;
    border-radius: 10px;
  }

  .homeDescription {
    width: 90%;
  }

  .homeDescription2 {
    width: 90%;
  }

  .homeButton {
    font-size: 14px;
  }
}
