footer {
  box-sizing: border-box;
  display: table;
  background-color: black;
  width: 100%;
  color: white;
  font-size: 18px;
  padding: 0 10%;
}

footer #brand {
  text-decoration: none;
  color: white;
  text-align: left;
  font-size: 28px;
}

footer .header {
  font-size: 20px;
  text-align: center;
  margin-bottom: 15px;
}

footer .footer-content {
  display: flex;
  justify-content: space-between;
  margin: 50px 0;
}

footer .footer-social {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

footer .footer-social-icons {
  display: flex;
  justify-content: space-between;
}

footer .footer-social-icons img {
  margin: 0 8px;
}

footer ul {
  padding-left: 0px;
  list-style-type: none;
}

footer li {
  margin-bottom: 1rem;
}

footer li a {
  color: white;
  text-decoration: none;
}

footer .footer-text{
  text-align: center;
}

@media only screen and (max-width: 380px) {
  footer .footer-social {
    justify-content: start;
  }

  footer .footer-social-icons {
    flex-direction: column;
    align-self: center;
  }

  footer .footer-social-icons img {
    margin: 4px 0;
  }
}