.tACBody{
    padding: 70px 0px;
}

.tACContent{
    width: 70%;
    margin: auto;
}

.tACContent h1{
    text-align: center;
}

.tACContent h2{
    text-align: center;
}