.privacyBody{
    background-color: #E9E9E9FF;
    padding-top: 50px;
    padding-bottom: 50px;
}

.privacyContent{
    background-color: #FFFFFF;
    width: 70%;
    margin: auto;
    margin-top: 2vh;
    margin-bottom: 2vh;
    padding: 1px 60px;
    border-radius: 4px;
    font-size: 18px;
}

.policyTitle{
    text-align: center;
}

@media only screen and (max-width: 1200px){
    .privacyContent{
        padding: 1px 20px;
        font-size: 16px;
    }
}

@media only screen and (max-width: 800px){
    .privacyContent{
        width: 80%;
        font-size: 14px;
    }
}

@media only screen and (max-width: 550px){
    .privacyContent{
        font-size: 12px;
    }
}