#navbar {
  z-index: 10;
  background-color: black;
  height: 50px;
  width: 100%;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: space-between;
  top: 0;
  --problem2-colour: #5493ce;
}

#navbar-brand {
  flex-grow: 1;
  text-align: center;
  margin: 10px;
}

.companyName {
  color: #ffffff;
  text-decoration: none;
  font-style: bold;
  font-size: 35px;
}

#navbar-search {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin-left: 15%;
  margin-top: 10px;
  flex-grow: 2;
}

#navbar-search input {
  text-align: center;
  font-size: 17px;
  border: none;
  border-radius: 10px;
  padding: 7px;
  margin-right: 4px;
  width: 40%;
  height: 40%;
}

#navbar-search button {
  text-transform: uppercase;
  font-weight: bold;
  color: white;
  background-color: #6cadf1;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px 12px 10px 12px;
}

#navbar-links-desktop {
  display: flex;
  justify-content: center;
  margin: 10px;
  margin-left: 0%;
  flex-grow: 1;
}

#navbar-links-desktop div {
  display: flex;
}

#navbar-links-desktop a {
  padding: 14px 10px;
  text-decoration: none;
  color: white;
}

#navbar-links-desktop a .link-disabled {
  padding: 14px 10px;
  text-decoration: none;
  color: white;
}

#navbar-links-desktop a:hover {
  background-color: #181919;
}

/* Mobile Navbar */

#navbar-links-mobile {
  flex-grow: 1;
  margin-left: 2%;
  justify-content: center;
  display: none;
}

#navbar-mobile-content {
  flex-direction: column;
  position: fixed;
  left: 1%;
  top: 50px;
  width: 98%;
}

#navbar-mobile-content a {
  padding: 14px 0px;
  text-decoration: none;
  text-align: center;
  background-color: #000000;
  color: white;
}

#navbar-mobile-content a:hover {
  background-color: #181919;
}

.navbar-active-link {
  background-color: #181919 !important;
}

.navbar-active-link2 {
  background-color: var(--problem2-colour) !important;
}

.hamburger {
  width: 30px;
  height: 2px;
  background-color: #ffffff;
  margin: 6px 0;
  border-radius: 10px;
}

.burgerToggle {
  cursor: pointer;
}

.search-suggestion-box {
  background-color: white;
  display: flex;
  flex-direction: column;
  border: 1px solid #6cadf1;
  border-top-style: none;
  border-radius: 10px;
}

.search-item {
  color: #000000;
  text-decoration: none;
  text-align: center;
  padding: 10px 0px;
  border-top: 1px solid #6cadf1;
  border-radius: 10px;
}

.search-item:hover {
  background-color: #181919;
  color: white;
}

@media only screen and (max-width: 1250px){
  #navbar-search input{
    height: 30%;
    width: 30%;
  }
}

@media only screen and (max-width: 900px) {
  #navbar-links-desktop {
    display: none;
  }

  #navbar-links-mobile {
    display: flex;
  }

  #navbar-mobile-content {
    display: none;
  }

  #navbar-search {
    margin-left: 2%;
  }
}
