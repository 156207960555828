.modal {
  margin: auto auto;
  max-width: 50vw;
  padding: 16px;
  color: white;
  background-color: black;
  border-radius: 10px;
}

.modal-header {
  font-size: 40px;
  text-align: center;
  margin: 0;
}

.modal-body {
  font-size: 18px;
}

.modal-body button {
  font-size: 18px;
  margin: 10px auto;
  padding: 8px 5%;
  background-color: #6cadf1;
  color: white;
  border: 2px solid #6cadf1;
  border-radius: 10px;
  cursor: pointer;
}

.modal-body input {
  margin: 5px 0 15px;
  border: 10px solid white;
  border-radius: 5px;
}

.modal-body a {
  text-decoration: none;
  color: #6cadf1;
}

@media only screen and (max-width: 450px) {
  .modal {
    max-width: none;
  }
}