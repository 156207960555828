.content {
  margin-top: 50px;
  background: url(../img/landingPage/background.jpg) no-repeat ;
  background-size: cover;
  background-position: top center;
  background-clip: padding-box;
  padding: 200px 0;
  width:100%;
  min-height: 15vh;
}

.content .modal{
  border-radius: 15px;
}

.content .modal div{
  text-align: center;
  margin: 4vh;
}

.form {
  display: grid;
  grid-template-columns: 1fr;
}