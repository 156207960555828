.studentSUForm {
  width: 90%;
  margin: auto;
}

#Test-fname {
  width: 100%;
  margin-right: 2%;
}

#Test-lname {
  width: 100%;
  margin-right: 2%;
}
#Test-email {
  width: 100%;
  margin-right: 2%;
}
#Test-age {
  width: 100%;
  margin-right: 2%;
}
#Test-stdnt {
  width: 100%;
  margin-right: 2%;
}
#Test-emp {
  width: 100%;
  margin-right: 2%;
}
#Test-choice {
  width: 100%;
  margin-right: 2%;
}
#Test-scale {
  width: 100%;
  margin-right: 2%;
}
.inputText {
  margin: 16px 0px;
  width: 60%;
  padding: 10px 30px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
  font-size: 1em;
  text-align: center;
}
.studentSUForm .homeButton {
  cursor: pointer;
  margin: 0px 10px;
  padding: 5px 100px;
  font-size: 34px;
  text-decoration: none;
  background-color: var(--main-colour);
  color: #ffffff;
  border: 2px solid var(--main-colour);
  border-radius: 10px;
}
.registerFormSpacing {
  text-align: left;
  margin: 0px;
}
.registerFormSpacingspan {
  text-align: left;
  margin: 0px;
  display: block;
  margin: 0 0 3px;
  font-size: 1.2em;
  font-weight: bold;
}
label {
  width: 30%;
  float: left;
  margin-left: 40px;
}
.rightLabel {
  width: 30%;
  float: right;
  margin-right: 50px;
}
.space {
  padding-bottom: 70px;
}
@media only screen and (max-width: 900px) {
  #firstName {
    width: 100%;
    margin-right: 0%;
  }

  #lastName {
    width: 100%;
    margin-left: 0%;
  }
}
